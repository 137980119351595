import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App.tsx';
import './index.css';
import { validateSupabaseConfig, initializeAuth } from './config/supabase';

// Initialize the application
async function init() {
  try {
    // Validate Supabase configuration
    if (!validateSupabaseConfig()) {
      throw new Error('Invalid Supabase configuration');
    }

    // Initialize auth and admin user
    await initializeAuth();

    // Render the application
    const root = document.getElementById('root');
    if (!root) throw new Error('Root element not found');

    createRoot(root).render(
      <StrictMode>
        <App />
      </StrictMode>
    );
  } catch (error) {
    console.error('Initialization error:', error);
    document.body.innerHTML = `
      <div style="color: red; padding: 20px;">
        Configuration Error: Please check your environment variables
      </div>
    `;
  }
}

init();