import React from 'react';
import { Sun, Moon } from 'lucide-react';
import { useThemeStore } from '../../stores/themeStore';

export default function ThemeToggle() {
  const { isDarkMode, toggleTheme } = useThemeStore();

  React.useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [isDarkMode]);

  return (
    <div className="relative group">
      <button
        onClick={toggleTheme}
        className="p-2 rounded-full transition-colors duration-200 hover:bg-gray-100 dark:hover:bg-dark-200"
      >
        {isDarkMode ? (
          <Sun className="h-6 w-6 text-primary" />
        ) : (
          <Moon className="h-6 w-6 text-neutral" />
        )}
      </button>
      <div className="absolute right-0 top-full mt-2 w-32 px-2 py-1 bg-white dark:bg-dark-200 text-sm text-gray-600 dark:text-gray-300 rounded shadow-lg opacity-0 group-hover:opacity-100 transition-opacity pointer-events-none">
        Switch to {isDarkMode ? 'light' : 'dark'} mode
      </div>
    </div>
  );
}