import React, { useState } from 'react';
import { ChevronDown, ChevronUp, MoreVertical, ClipboardList, FileText, CheckCircle, AlertCircle } from 'lucide-react';
import { format } from 'date-fns';

interface Order {
  id: string;
  assetType: string;
  quantity: number;
  stage: string;
  status: string;
  completion: number;
  description: string;
}

const orders: Order[] = [
  {
    id: 'Dwmpl_001',
    assetType: 'Laptop',
    quantity: 100,
    stage: 'Inspection',
    status: 'Completed',
    completion: 50,
    description: 'Physical assessment of assets',
  },
  {
    id: 'Dwmpl_002',
    assetType: 'Laptop',
    quantity: 200,
    stage: 'Inspection',
    status: 'In Progress',
    completion: 30,
    description: 'Physical assessment of assets',
  },
];

export default function OrdersPage() {
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
  const [sortConfig, setSortConfig] = useState<{
    key: keyof Order;
    direction: 'asc' | 'desc';
  } | null>(null);

  const handleSort = (key: keyof Order) => {
    setSortConfig({
      key,
      direction: sortConfig?.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc',
    });
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold text-gray-900 dark:text-white">Order Details</h1>
      </div>

      <div className="bg-white dark:bg-dark-100 rounded-lg shadow-sm overflow-hidden">
        <div className="px-4 py-3 bg-primary/10 dark:bg-primary/20 border-b border-primary/20">
          <h2 className="text-lg font-medium text-primary">Orders List</h2>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200 dark:divide-dark-300">
            <thead className="bg-gray-50 dark:bg-dark-200">
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400">
                  <input type="checkbox" className="rounded border-gray-300 text-primary focus:ring-primary dark:border-dark-300 dark:bg-dark-100" />
                </th>
                <th 
                  scope="col" 
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 cursor-pointer"
                  onClick={() => handleSort('id')}
                >
                  <div className="flex items-center space-x-1">
                    <span>Order ID</span>
                    <div className="flex flex-col">
                      <ChevronUp className="h-3 w-3" />
                      <ChevronDown className="h-3 w-3" />
                    </div>
                  </div>
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400">
                  <div className="flex items-center space-x-1">
                    <span>Asset Types</span>
                    <div className="flex flex-col">
                      <ChevronUp className="h-3 w-3" />
                      <ChevronDown className="h-3 w-3" />
                    </div>
                  </div>
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400">
                  <div className="flex items-center space-x-1">
                    <span>Quantities</span>
                    <div className="flex flex-col">
                      <ChevronUp className="h-3 w-3" />
                      <ChevronDown className="h-3 w-3" />
                    </div>
                  </div>
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400">
                  Progress Tracker
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white dark:bg-dark-100 divide-y divide-gray-200 dark:divide-dark-300">
              {orders.map((order) => (
                <tr 
                  key={order.id} 
                  className={`hover:bg-gray-50 dark:hover:bg-dark-200 ${selectedOrder?.id === order.id ? 'bg-primary/5 dark:bg-primary/10' : ''}`}
                  onClick={() => setSelectedOrder(order)}
                >
                  <td className="px-6 py-4 whitespace-nowrap">
                    <input type="checkbox" className="rounded border-gray-300 text-primary focus:ring-primary dark:border-dark-300 dark:bg-dark-100" />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-white">
                    {order.id}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                    {order.assetType}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                    {order.quantity}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`inline-flex px-3 py-1 text-xs font-medium rounded-full ${
                      order.status === 'Completed'
                        ? 'bg-secondary/10 text-secondary dark:bg-secondary/20'
                        : 'bg-primary/10 text-primary dark:bg-primary/20'
                    }`}>
                      {order.stage}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                    <button className="text-gray-400 hover:text-gray-500 dark:text-gray-500 dark:hover:text-gray-400">
                      <MoreVertical className="h-5 w-5" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {selectedOrder && (
        <div className="bg-white dark:bg-dark-100 rounded-lg shadow-sm overflow-hidden">
          <div className="px-4 py-3 bg-primary/10 dark:bg-primary/20 border-b border-primary/20">
            <h3 className="text-lg font-medium text-primary">Order Progress Details</h3>
          </div>
          <div className="p-6 grid grid-cols-2 gap-6">
            <div className="space-y-4">
              <div className="flex items-center space-x-3">
                <ClipboardList className="h-5 w-5 text-primary" />
                <div>
                  <p className="font-medium text-gray-500 dark:text-gray-400">Stage</p>
                  <p className="text-gray-900 dark:text-white">{selectedOrder.stage}</p>
                </div>
              </div>
              <div className="flex items-center space-x-3">
                <FileText className="h-5 w-5 text-primary" />
                <div>
                  <p className="font-medium text-gray-500 dark:text-gray-400">Description</p>
                  <p className="text-gray-900 dark:text-white">{selectedOrder.description}</p>
                </div>
              </div>
              <div className="flex items-center space-x-3">
                <AlertCircle className="h-5 w-5 text-primary" />
                <div>
                  <p className="font-medium text-gray-500 dark:text-gray-400">Status</p>
                  <p className="text-gray-900 dark:text-white">{selectedOrder.status}</p>
                </div>
              </div>
              <div className="flex items-center space-x-3">
                <CheckCircle className="h-5 w-5 text-primary" />
                <div>
                  <p className="font-medium text-gray-500 dark:text-gray-400">Completion</p>
                  <p className="text-gray-900 dark:text-white">{selectedOrder.completion}%</p>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center">
              <div className="w-48 h-48 relative">
                <div className="absolute inset-0 flex items-center justify-center">
                  <div className="text-2xl font-bold text-gray-900 dark:text-white">{selectedOrder.completion}%</div>
                </div>
                <svg className="transform -rotate-90 w-48 h-48">
                  <circle
                    cx="96"
                    cy="96"
                    r="88"
                    stroke="currentColor"
                    strokeWidth="12"
                    fill="none"
                    className="text-gray-200 dark:text-dark-300 opacity-25"
                  />
                  <circle
                    cx="96"
                    cy="96"
                    r="88"
                    stroke="currentColor"
                    strokeWidth="12"
                    fill="none"
                    strokeDasharray={2 * Math.PI * 88}
                    strokeDashoffset={2 * Math.PI * 88 * (1 - selectedOrder.completion / 100)}
                    className="text-primary"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}