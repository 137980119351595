import React from 'react';
import { BarChart3, TrendingUp, PieChart, Activity } from 'lucide-react';
import { useZohoStore } from '../stores/zohoStore';
import ZohoStats from '../components/zoho/ZohoStats';
import AnalyticsChart from '../components/analytics/AnalyticsChart';

const monthlyData = [
  { month: 'Jan', orders: 65, revenue: 89000 },
  { month: 'Feb', orders: 75, revenue: 95000 },
  { month: 'Mar', orders: 85, revenue: 103000 },
  { month: 'Apr', orders: 95, revenue: 108000 },
  { month: 'May', orders: 110, revenue: 125000 },
  { month: 'Jun', orders: 125, revenue: 140000 },
];

const assetTypes = [
  { type: 'Laptops', count: 450 },
  { type: 'Desktops', count: 300 },
  { type: 'Servers', count: 150 },
  { type: 'Network Equipment', count: 100 },
];

export default function AnalyticsPage() {
  const { stats } = useZohoStore();

  const chartData = {
    labels: monthlyData.map(d => d.month),
    datasets: [
      {
        label: 'Monthly Revenue',
        data: monthlyData.map(d => d.revenue),
        borderColor: '#F7941D',
        backgroundColor: 'rgba(247, 148, 29, 0.1)',
        fill: true,
      },
    ],
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold text-gray-900 dark:text-white">Analytics and Graphs</h1>
      </div>

      <ZohoStats />

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Monthly Orders Trend */}
        <div className="bg-white dark:bg-dark-100 rounded-lg shadow-sm overflow-hidden">
          <div className="px-4 py-3 bg-primary/10 dark:bg-primary/20 border-b border-primary/20">
            <div className="flex items-center justify-between">
              <h3 className="text-lg font-medium text-primary">Monthly Orders</h3>
              <BarChart3 className="h-5 w-5 text-primary" />
            </div>
          </div>
          <div className="p-6">
            <AnalyticsChart 
              data={{
                labels: monthlyData.map(d => d.month),
                datasets: [{
                  label: 'Orders',
                  data: monthlyData.map(d => d.orders),
                  borderColor: '#37B013',
                  backgroundColor: 'rgba(55, 176, 19, 0.1)',
                  fill: true,
                }],
              }}
            />
          </div>
        </div>

        {/* Revenue Growth */}
        <div className="bg-white dark:bg-dark-100 rounded-lg shadow-sm overflow-hidden">
          <div className="px-4 py-3 bg-primary/10 dark:bg-primary/20 border-b border-primary/20">
            <div className="flex items-center justify-between">
              <h3 className="text-lg font-medium text-primary">Revenue Growth</h3>
              <TrendingUp className="h-5 w-5 text-primary" />
            </div>
          </div>
          <div className="p-6">
            <AnalyticsChart data={chartData} />
          </div>
        </div>

        {/* Asset Distribution */}
        <div className="bg-white dark:bg-dark-100 rounded-lg shadow-sm overflow-hidden">
          <div className="px-4 py-3 bg-primary/10 dark:bg-primary/20 border-b border-primary/20">
            <div className="flex items-center justify-between">
              <h3 className="text-lg font-medium text-primary">Asset Distribution</h3>
              <PieChart className="h-5 w-5 text-primary" />
            </div>
          </div>
          <div className="p-6">
            <div className="grid grid-cols-2 gap-4">
              {assetTypes.map((asset) => (
                <div key={asset.type} className="bg-gray-50 dark:bg-dark-200 p-4 rounded-lg">
                  <h4 className="text-sm font-medium text-gray-600 dark:text-gray-400">{asset.type}</h4>
                  <p className="text-2xl font-semibold text-gray-900 dark:text-white mt-2">{asset.count}</p>
                  <div className="mt-2 h-2 bg-gray-200 dark:bg-dark-300 rounded-full">
                    <div
                      className="h-full bg-primary rounded-full"
                      style={{ width: `${(asset.count / 1000) * 100}%` }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Performance Metrics */}
        <div className="bg-white dark:bg-dark-100 rounded-lg shadow-sm overflow-hidden">
          <div className="px-4 py-3 bg-primary/10 dark:bg-primary/20 border-b border-primary/20">
            <div className="flex items-center justify-between">
              <h3 className="text-lg font-medium text-primary">Performance Metrics</h3>
              <Activity className="h-5 w-5 text-primary" />
            </div>
          </div>
          <div className="p-6">
            <div className="space-y-4">
              {[
                { label: 'Processing Time', value: '2.5 days', target: '3 days', progress: 85 },
                { label: 'Customer Satisfaction', value: '4.8/5', target: '4.5/5', progress: 95 },
                { label: 'On-time Delivery', value: '98%', target: '95%', progress: 98 },
                { label: 'Documentation Accuracy', value: '99.5%', target: '99%', progress: 99 },
              ].map((metric) => (
                <div key={metric.label} className="space-y-2">
                  <div className="flex justify-between">
                    <span className="text-sm font-medium text-gray-600 dark:text-gray-400">{metric.label}</span>
                    <span className="text-sm text-gray-900 dark:text-white">{metric.value}</span>
                  </div>
                  <div className="h-2 bg-gray-200 dark:bg-dark-300 rounded-full">
                    <div
                      className="h-full bg-primary rounded-full"
                      style={{ width: `${metric.progress}%` }}
                    />
                  </div>
                  <div className="flex justify-between text-xs text-gray-500 dark:text-gray-400">
                    <span>Target: {metric.target}</span>
                    <span>{metric.progress}% achieved</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}