export const ZOHO_CONFIG = {
  clientId: import.meta.env.VITE_ZOHO_CLIENT_ID,
  clientSecret: import.meta.env.VITE_ZOHO_CLIENT_SECRET,
  refreshToken: import.meta.env.VITE_ZOHO_REFRESH_TOKEN,
  apiUrl: import.meta.env.VITE_ZOHO_API_URL,
  authDomain: 'https://accounts.zoho.in',
  apiDomain: 'https://www.zohoapis.in',
};

let accessToken: string | null = null;

export const getAccessToken = async () => {
  if (!accessToken) {
    try {
      const response = await fetch(`${ZOHO_CONFIG.authDomain}/oauth/v2/token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          refresh_token: ZOHO_CONFIG.refreshToken,
          client_id: ZOHO_CONFIG.clientId,
          client_secret: ZOHO_CONFIG.clientSecret,
          grant_type: 'refresh_token',
        }),
      });

      const data = await response.json();
      if (data.error) {
        throw new Error(data.error);
      }
      
      accessToken = data.access_token;
    } catch (error) {
      console.error('Failed to get Zoho access token:', error);
      throw error;
    }
  }
  return accessToken;
};

export const zohoApi = {
  searchByEmail: async (email: string) => {
    try {
      const token = await getAccessToken();
      const url = ZOHO_CONFIG.apiUrl.replace('$email', encodeURIComponent(email));
      
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Zoho API error: ${response.statusText}`);
      }

      return response.json();
    } catch (error) {
      console.error('Zoho API error:', error);
      throw error;
    }
  },
};