import { createClient } from '@supabase/supabase-js';
import type { Database } from '../types/supabase';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

export const validateSupabaseConfig = () => {
  if (!supabaseUrl || !supabaseAnonKey) {
    console.error('Missing Supabase environment variables');
    return false;
  }
  return true;
};

export const supabase = createClient<Database>(supabaseUrl, supabaseAnonKey);

// Initialize admin user
export const initializeAdminUser = async () => {
  try {
    // First check if admin exists in auth
    const { data: authData } = await supabase.auth.signInWithPassword({
      email: 'info@dwmpl.com',
      password: 'Dwmpl@123',
    });

    if (!authData.user) {
      // Create admin user in auth
      const { data: newAuthUser, error: signUpError } = await supabase.auth.signUp({
        email: 'info@dwmpl.com',
        password: 'Dwmpl@123',
      });

      if (signUpError) throw signUpError;

      if (newAuthUser.user) {
        // Create admin profile
        const { error: profileError } = await supabase
          .from('profiles')
          .insert({
            id: newAuthUser.user.id,
            first_name: 'Admin',
            last_name: 'User',
            email: 'info@dwmpl.com',
            organization: 'Deshwal Waste Management',
            phone: '1800-102-9077',
            role: 'admin',
            status: 'approved',
          });

        if (profileError) throw profileError;
      }
    }

    // Sign out after initialization
    await supabase.auth.signOut();
  } catch (error) {
    console.error('Error initializing admin user:', error);
  }
};

// Initialize auth
export const initializeAuth = async () => {
  await initializeAdminUser();
  return supabase.auth.getSession();
};