import React from 'react';
import { MessageSquare, FileText, Phone, Mail, MapPin } from 'lucide-react';

const WHATSAPP_NUMBER = '+919555007646';
const TOLL_FREE = '1800 102 9077';
const EMAIL = 'info@dwmpl.com';
const OFFICE_ADDRESS = 'Plot no 79, 1st Floor, Sector 34, Hero Honda Chowk, Gurugram, Haryana 122004';
const KNOWLEDGE_BASE_URL = 'https://www.dwmpl.com/about-us/';

export default function SupportPage() {
  const openWhatsApp = () => {
    window.open(`https://wa.me/${WHATSAPP_NUMBER.replace('+', '')}`, '_blank');
  };

  const openKnowledgeBase = () => {
    window.open(KNOWLEDGE_BASE_URL, '_blank');
  };

  return (
    <div className="space-y-6">
      <h1 className="text-2xl font-semibold text-gray-900 dark:text-white">Support</h1>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="bg-white dark:bg-dark-100 rounded-lg shadow-sm overflow-hidden">
          <div className="px-4 py-3 bg-primary/10 dark:bg-primary/20 border-b border-primary/20">
            <div className="flex items-center">
              <MessageSquare className="h-6 w-6 text-primary mr-2" />
              <h2 className="text-lg font-medium text-primary">WhatsApp Support</h2>
            </div>
          </div>
          <div className="p-6">
            <p className="text-gray-500 dark:text-gray-400 mb-4">Get instant help via WhatsApp</p>
            <button 
              onClick={openWhatsApp}
              className="w-full px-4 py-2 bg-primary text-white rounded-md hover:bg-primary/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary dark:focus:ring-offset-dark-100"
            >
              Chat on WhatsApp
            </button>
          </div>
        </div>

        <div className="bg-white dark:bg-dark-100 rounded-lg shadow-sm overflow-hidden">
          <div className="px-4 py-3 bg-primary/10 dark:bg-primary/20 border-b border-primary/20">
            <div className="flex items-center">
              <FileText className="h-6 w-6 text-primary mr-2" />
              <h2 className="text-lg font-medium text-primary">Knowledge Base</h2>
            </div>
          </div>
          <div className="p-6">
            <p className="text-gray-500 dark:text-gray-400 mb-4">Browse our help articles and guides</p>
            <button 
              onClick={openKnowledgeBase}
              className="w-full px-4 py-2 bg-primary text-white rounded-md hover:bg-primary/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary dark:focus:ring-offset-dark-100"
            >
              View Articles
            </button>
          </div>
        </div>

        <div className="bg-white dark:bg-dark-100 rounded-lg shadow-sm overflow-hidden">
          <div className="px-4 py-3 bg-primary/10 dark:bg-primary/20 border-b border-primary/20">
            <div className="flex items-center">
              <Phone className="h-6 w-6 text-primary mr-2" />
              <h2 className="text-lg font-medium text-primary">Call Support</h2>
            </div>
          </div>
          <div className="p-6">
            <p className="text-gray-500 dark:text-gray-400 mb-4">Reach out to our support team</p>
            <a 
              href={`tel:${TOLL_FREE}`}
              className="w-full px-4 py-2 bg-primary text-white rounded-md hover:bg-primary/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary dark:focus:ring-offset-dark-100 inline-block text-center"
            >
              Call {TOLL_FREE}
            </a>
          </div>
        </div>
      </div>

      {/* Contact Information */}
      <div className="bg-white dark:bg-dark-100 rounded-lg shadow-sm overflow-hidden">
        <div className="px-4 py-3 bg-primary/10 dark:bg-primary/20 border-b border-primary/20">
          <h2 className="text-lg font-medium text-primary">Contact Information</h2>
        </div>
        <div className="p-6 space-y-4">
          <div className="flex items-start">
            <MapPin className="h-6 w-6 text-primary mr-3 flex-shrink-0 mt-1" />
            <div>
              <h3 className="font-medium text-gray-900 dark:text-white">Corporate Office</h3>
              <p className="text-gray-600 dark:text-gray-400">{OFFICE_ADDRESS}</p>
            </div>
          </div>
          
          <div className="flex items-center">
            <Phone className="h-6 w-6 text-primary mr-3" />
            <div>
              <h3 className="font-medium text-gray-900 dark:text-white">Toll Free</h3>
              <a href={`tel:${TOLL_FREE}`} className="text-primary hover:text-primary/80">
                {TOLL_FREE}
              </a>
            </div>
          </div>

          <div className="flex items-center">
            <Mail className="h-6 w-6 text-primary mr-3" />
            <div>
              <h3 className="font-medium text-gray-900 dark:text-white">Email</h3>
              <a href={`mailto:${EMAIL}`} className="text-primary hover:text-primary/80">
                {EMAIL}
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white dark:bg-dark-100 rounded-lg shadow-sm overflow-hidden">
        <div className="px-4 py-3 bg-primary/10 dark:bg-primary/20 border-b border-primary/20">
          <h2 className="text-lg font-medium text-primary">Recent Tickets</h2>
        </div>
        <div className="p-6">
          <div className="space-y-4">
            {[1, 2, 3].map((i) => (
              <div key={i} className="flex items-center justify-between p-4 bg-gray-50 dark:bg-dark-200 rounded-lg">
                <div>
                  <p className="font-medium text-gray-900 dark:text-white">Support Ticket #{1000 + i}</p>
                  <p className="text-sm text-gray-500 dark:text-gray-400">Last updated 2 hours ago</p>
                </div>
                <span className="px-2 py-1 text-xs font-medium rounded-full bg-primary/10 text-primary dark:bg-primary/20">
                  In Progress
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}