import { create } from 'zustand';
import axios from 'axios';
import { ZOHO_CONFIG } from '../config/zoho';
import type { ZohoStore, ZohoAuthResponse, ZohoLead } from '../types/zoho';

export const useZohoStore = create<ZohoStore>((set, get) => ({
  accessToken: ZOHO_CONFIG.refreshToken, // Use refresh token directly for simplicity
  refreshToken: ZOHO_CONFIG.refreshToken,
  isAuthenticated: true, // Set to true since we're using direct token
  stats: null,
  error: null,
  isLoading: false,

  authenticate: async (code: string) => {
    set({ isLoading: true, error: null });
    try {
      const response = await axios.post<ZohoAuthResponse>(
        `${ZOHO_CONFIG.authDomain}/oauth/v2/token`,
        null,
        {
          params: {
            code,
            client_id: ZOHO_CONFIG.clientId,
            client_secret: ZOHO_CONFIG.clientSecret,
            redirect_uri: ZOHO_CONFIG.redirectUri,
            grant_type: 'authorization_code',
          },
        }
      );

      set({
        accessToken: response.data.access_token,
        refreshToken: response.data.refresh_token,
        isAuthenticated: true,
        isLoading: false,
      });

      await get().fetchStats();
    } catch (error) {
      set({
        error: error instanceof Error ? error.message : 'Authentication failed',
        isLoading: false,
      });
    }
  },

  refreshAccessToken: async () => {
    try {
      const response = await axios.post<ZohoAuthResponse>(
        `${ZOHO_CONFIG.authDomain}/oauth/v2/token`,
        null,
        {
          params: {
            refresh_token: ZOHO_CONFIG.refreshToken,
            client_id: ZOHO_CONFIG.clientId,
            client_secret: ZOHO_CONFIG.clientSecret,
            grant_type: 'refresh_token',
          },
        }
      );

      set({
        accessToken: response.data.access_token,
        isAuthenticated: true,
      });
      
      return response.data.access_token;
    } catch (error) {
      set({
        error: error instanceof Error ? error.message : 'Token refresh failed',
        isAuthenticated: false,
      });
      throw error;
    }
  },

  fetchStats: async () => {
    set({ isLoading: true });
    try {
      let token = get().accessToken;
      
      // If no token, try to refresh
      if (!token) {
        token = await get().refreshAccessToken();
      }

      const response = await axios.get(
        ZOHO_CONFIG.apiUrl,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const leads = response.data.data || [];
      const now = new Date();
      const weekAgo = new Date(now.setDate(now.getDate() - 7));

      const stats = {
        totalLeads: leads.length,
        newLeadsThisWeek: leads.filter(
          (lead: ZohoLead) => new Date(lead.Created_Time) >= weekAgo
        ).length,
        convertedLeads: leads.filter(
          (lead: ZohoLead) => lead.Lead_Status === 'Converted'
        ).length,
        pendingFollowUps: leads.filter(
          (lead: ZohoLead) => lead.Lead_Status === 'Contact in Future'
        ).length,
      };

      set({ stats, isLoading: false });
    } catch (error) {
      set({
        error: error instanceof Error ? error.message : 'Failed to fetch stats',
        isLoading: false,
      });
    }
  },

  logout: () => {
    set({
      accessToken: null,
      refreshToken: null,
      isAuthenticated: false,
      stats: null,
      error: null,
    });
  },
}));