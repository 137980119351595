import React from 'react';
import { Truck, Package, Calendar, User, Phone, Navigation } from 'lucide-react';

interface TrackingDetails {
  dwmplSpoc: {
    name: string;
    contactNumber: string;
  };
  customerSpoc: {
    name: string;
    contactNumber: string;
  };
  vehicle: {
    number: string;
    docketNumber: string;
    tentativeDeliveryDate: string;
    deliveredDate: string;
    currentLocation: {
      lat: number;
      lng: number;
      address: string;
    };
  };
}

const trackingDetails: TrackingDetails = {
  dwmplSpoc: {
    name: 'Aman',
    contactNumber: '91-9876543210',
  },
  customerSpoc: {
    name: 'Ranjan',
    contactNumber: '91-9876543210',
  },
  vehicle: {
    number: 'HR-009090',
    docketNumber: 'TU090',
    tentativeDeliveryDate: '01-Dec-2024',
    deliveredDate: '05-Dec-2024',
    currentLocation: {
      lat: 28.4595,
      lng: 77.0266,
      address: 'Hero Honda Chowk, Gurugram'
    }
  },
};

export default function LogisticsPage() {
  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold text-gray-900 dark:text-white">Tracking</h1>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* DWMPL SPOC Details */}
        <div className="bg-white dark:bg-dark-100 rounded-lg shadow-sm overflow-hidden">
          <div className="px-4 py-3 bg-primary/10 dark:bg-primary/20 border-b border-primary/20">
            <h2 className="text-lg font-medium text-primary">DWMPL SPOC Details:</h2>
          </div>
          <div className="p-6 space-y-4">
            <div className="flex items-center space-x-4">
              <User className="h-5 w-5 text-primary" />
              <div>
                <p className="text-sm text-gray-500 dark:text-gray-400">Deshwal SPOC Name</p>
                <p className="font-medium text-gray-900 dark:text-white">{trackingDetails.dwmplSpoc.name}</p>
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <Phone className="h-5 w-5 text-primary" />
              <div>
                <p className="text-sm text-gray-500 dark:text-gray-400">Deshwal SPOC Contact Number</p>
                <p className="font-medium text-gray-900 dark:text-white">{trackingDetails.dwmplSpoc.contactNumber}</p>
              </div>
            </div>
          </div>
        </div>

        {/* Vehicle Details */}
        <div className="bg-white dark:bg-dark-100 rounded-lg shadow-sm overflow-hidden">
          <div className="px-4 py-3 bg-primary/10 dark:bg-primary/20 border-b border-primary/20">
            <h2 className="text-lg font-medium text-primary">Vehicle Details:</h2>
          </div>
          <div className="p-6 space-y-4">
            <div className="grid grid-cols-2 gap-4">
              <div className="flex items-center space-x-4">
                <Truck className="h-5 w-5 text-primary" />
                <div>
                  <p className="text-sm text-gray-500 dark:text-gray-400">Vehicle Number</p>
                  <p className="font-medium text-gray-900 dark:text-white">{trackingDetails.vehicle.number}</p>
                </div>
              </div>
              <div className="flex items-center space-x-4">
                <Package className="h-5 w-5 text-primary" />
                <div>
                  <p className="text-sm text-gray-500 dark:text-gray-400">Docket Number</p>
                  <p className="font-medium text-gray-900 dark:text-white">{trackingDetails.vehicle.docketNumber}</p>
                </div>
              </div>
              <div className="flex items-center space-x-4">
                <Calendar className="h-5 w-5 text-primary" />
                <div>
                  <p className="text-sm text-gray-500 dark:text-gray-400">Tentative Delivery Date</p>
                  <p className="font-medium text-gray-900 dark:text-white">{trackingDetails.vehicle.tentativeDeliveryDate}</p>
                </div>
              </div>
              <div className="flex items-center space-x-4">
                <Calendar className="h-5 w-5 text-primary" />
                <div>
                  <p className="text-sm text-gray-500 dark:text-gray-400">Delivered Date</p>
                  <p className="font-medium text-gray-900 dark:text-white">{trackingDetails.vehicle.deliveredDate}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Customer SPOC Details */}
        <div className="bg-white dark:bg-dark-100 rounded-lg shadow-sm overflow-hidden">
          <div className="px-4 py-3 bg-primary/10 dark:bg-primary/20 border-b border-primary/20">
            <h2 className="text-lg font-medium text-primary">Customer SPOC Details:</h2>
          </div>
          <div className="p-6 space-y-4">
            <div className="flex items-center space-x-4">
              <User className="h-5 w-5 text-primary" />
              <div>
                <p className="text-sm text-gray-500 dark:text-gray-400">Customer SPOC Name</p>
                <p className="font-medium text-gray-900 dark:text-white">{trackingDetails.customerSpoc.name}</p>
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <Phone className="h-5 w-5 text-primary" />
              <div>
                <p className="text-sm text-gray-500 dark:text-gray-400">SPOC Contact Number</p>
                <p className="font-medium text-gray-900 dark:text-white">{trackingDetails.customerSpoc.contactNumber}</p>
              </div>
            </div>
          </div>
        </div>

        {/* Live Tracking Map */}
        <div className="bg-white dark:bg-dark-100 rounded-lg shadow-sm overflow-hidden">
          <div className="px-4 py-3 bg-primary/10 dark:bg-primary/20 border-b border-primary/20">
            <h2 className="text-lg font-medium text-primary">Live Tracking Map</h2>
          </div>
          <div className="p-6">
            <div className="bg-gray-100 dark:bg-dark-200 rounded-lg overflow-hidden">
              <iframe
                src={`https://www.google.com/maps/embed/v1/place?key=YOUR_GOOGLE_MAPS_API_KEY&q=${trackingDetails.vehicle.currentLocation.lat},${trackingDetails.vehicle.currentLocation.lng}`}
                width="100%"
                height="300"
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </div>
            <div className="mt-4 flex items-start space-x-3 p-4 bg-primary/5 dark:bg-primary/10 rounded-lg">
              <Navigation className="h-5 w-5 text-primary flex-shrink-0 mt-0.5" />
              <div>
                <p className="text-sm font-medium text-gray-900 dark:text-white">Current Location</p>
                <p className="text-sm text-gray-500 dark:text-gray-400">{trackingDetails.vehicle.currentLocation.address}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}