import React from 'react';
import { Link } from 'react-router-dom';
import { Settings, Bell } from 'lucide-react';
import { useAuthStore } from '../../stores/authStore';
import NotificationPanel from '../common/NotificationPanel';
import ThemeToggle from '../common/ThemeToggle';

interface HeaderProps {
  isSidebarOpen: boolean;
}

export default function Header({ isSidebarOpen }: HeaderProps) {
  const user = useAuthStore((state) => state.user);
  const firstName = user?.firstName?.split(' ')[0] || '';

  return (
    <header className="bg-white dark:bg-dark-100 border-b border-gray-100 dark:border-dark-300">
      <div className="px-4 sm:px-6">
        <div className="flex items-center justify-end h-16">
          {/* Customer Info & Logo */}
          <div className="flex items-center space-x-8">
            {/* Company Info */}
            <div className="flex items-center">
              <div className="text-right mr-4">
                <h3 className="text-sm font-medium text-gray-900 dark:text-white">Welcome, {firstName}</h3>
                <p className="text-xs text-gray-500 dark:text-gray-400">Client Portal</p>
              </div>
              {/* Company Logo */}
              <div className="h-10 w-10 bg-gray-200 dark:bg-dark-200 rounded-lg flex items-center justify-center">
                <img
                  src="https://via.placeholder.com/40"
                  alt={`${firstName} logo`}
                  className="h-8 w-8 rounded"
                />
              </div>
            </div>

            {/* Divider */}
            <div className="h-8 w-px bg-gray-200 dark:bg-dark-300"></div>

            {/* Action Icons */}
            <div className="flex items-center space-x-4">
              <ThemeToggle />
              <NotificationPanel />
              <Link 
                to="/settings"
                className="p-2 text-gray-400 hover:text-gray-500 dark:text-gray-500 dark:hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-primary rounded-xl hover:bg-gray-50 dark:hover:bg-dark-200"
              >
                <span className="sr-only">Settings</span>
                <Settings className="h-6 w-6" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}