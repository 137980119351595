import React from 'react';
import { TrendingUp, TrendingDown } from 'lucide-react';
import { format } from 'date-fns';

const stats = [
  {
    label: 'Active Orders',
    value: '7,265',
    change: '+11.01%',
    trend: 'up',
  },
  {
    label: 'Completed Orders',
    value: '3,671',
    change: '-0.03%',
    trend: 'down',
  },
  {
    label: 'Pending Invoices',
    value: '156',
    change: '+15.03%',
    trend: 'up',
  },
  {
    label: 'Total Assets Processed',
    value: '2,318',
    change: '+6.08%',
    trend: 'up',
  },
];

const orders = [
  {
    id: 'Dwmpl_001',
    orderDate: '01-Dec-2024',
    expectedCompletion: '15-Dec-2024',
    status: 'Active',
  },
  {
    id: 'Dwmpl_002',
    orderDate: '01-Dec-2024',
    expectedCompletion: '15-Dec-2024',
    status: 'Active',
  },
  {
    id: 'Dwmpl_003',
    orderDate: '01-Dec-2024',
    expectedCompletion: '15-Dec-2024',
    status: 'Active',
  },
  {
    id: 'Dwmpl_004',
    orderDate: '01-Dec-2024',
    expectedCompletion: '15-Dec-2024',
    status: 'Pending',
  },
];

export default function DashboardPage() {
  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold text-gray-900 dark:text-white">Overview</h1>
        <div className="flex items-center space-x-4">
          <span className="text-sm text-gray-500 dark:text-gray-400">
            {format(new Date(), 'dd MMM yyyy')}
          </span>
        </div>
      </div>

      {/* Stats Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {stats.map((stat) => (
          <div
            key={stat.label}
            className="bg-white dark:bg-dark-100 rounded-lg shadow-sm overflow-hidden"
          >
            <div className="px-4 py-3 bg-primary/10 dark:bg-primary/20 border-b border-primary/20">
              <p className="text-sm font-medium text-primary">{stat.label}</p>
            </div>
            <div className="p-4">
              <div className="flex items-baseline">
                <p className="text-3xl font-semibold text-gray-900 dark:text-white">{stat.value}</p>
                <span
                  className={`ml-2 text-sm font-medium flex items-center ${
                    stat.trend === 'up' ? 'text-secondary' : 'text-red-500'
                  }`}
                >
                  {stat.trend === 'up' ? (
                    <TrendingUp className="h-4 w-4 mr-1" />
                  ) : (
                    <TrendingDown className="h-4 w-4 mr-1" />
                  )}
                  {stat.change}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Orders Table */}
      <div className="bg-white dark:bg-dark-100 rounded-lg shadow-sm overflow-hidden">
        <div className="px-4 py-3 bg-primary/10 dark:bg-primary/20 border-b border-primary/20">
          <h2 className="text-lg font-medium text-primary">Recent Orders</h2>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200 dark:divide-dark-300">
            <thead className="bg-gray-50 dark:bg-dark-200">
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400">
                  <input type="checkbox" className="rounded border-gray-300 text-primary focus:ring-primary dark:border-dark-300 dark:bg-dark-100" />
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400">
                  Order ID
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400">
                  Order Date
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400">
                  Expected Completion
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400">
                  Status
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="bg-white dark:bg-dark-100 divide-y divide-gray-200 dark:divide-dark-300">
              {orders.map((order) => (
                <tr key={order.id} className="hover:bg-gray-50 dark:hover:bg-dark-200">
                  <td className="px-6 py-4 whitespace-nowrap">
                    <input type="checkbox" className="rounded border-gray-300 text-primary focus:ring-primary dark:border-dark-300 dark:bg-dark-100" />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-white">
                    {order.id}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                    {order.orderDate}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                    {order.expectedCompletion}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span
                      className={`inline-flex px-3 py-1 text-xs font-medium rounded-full ${
                        order.status === 'Active'
                          ? 'bg-secondary/10 text-secondary dark:bg-secondary/20'
                          : 'bg-primary/10 text-primary dark:bg-primary/20'
                      }`}
                    >
                      {order.status}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                    <button className="text-gray-400 hover:text-gray-500 dark:text-gray-500 dark:hover:text-gray-400">
                      <span className="sr-only">Open options</span>
                      <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
                      </svg>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}