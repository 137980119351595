import React, { useState } from 'react';
import { ChevronDown, ChevronUp, MoreVertical, IndianRupee, TrendingUp, Clock, AlertCircle } from 'lucide-react';
import CurrencyDisplay from '../components/common/CurrencyDisplay';

interface Payment {
  id: string;
  invoiceNumber: string;
  invoiceDate: string;
  amount: number;
  bankName: string;
}

const payments: Payment[] = [
  {
    id: '1',
    invoiceNumber: 'INV_001',
    invoiceDate: '01-Dec-2024',
    amount: 10956,
    bankName: 'HDFC',
  },
  {
    id: '2',
    invoiceNumber: 'INV_002',
    invoiceDate: '01-Dec-2024',
    amount: 98766,
    bankName: 'SBI',
  },
  {
    id: '3',
    invoiceNumber: 'INV_002',
    invoiceDate: '01-Dec-2024',
    amount: 65755,
    bankName: 'HDFC',
  },
];

const financialMetrics = [
  {
    label: 'Total Revenue',
    value: 2500000,
    icon: IndianRupee,
    trend: '+12.5%',
    color: 'text-secondary',
  },
  {
    label: 'Pending Payments',
    value: 150000,
    icon: Clock,
    trend: '-2.3%',
    color: 'text-primary',
  },
  {
    label: 'Overdue Amount',
    value: 75000,
    icon: AlertCircle,
    trend: '+5.1%',
    color: 'text-red-500',
  },
  {
    label: 'Monthly Growth',
    value: 350000,
    icon: TrendingUp,
    trend: '+8.4%',
    color: 'text-secondary',
  },
];

export default function FinancePage() {
  const [selectedPayments, setSelectedPayments] = useState<string[]>([]);
  const [sortConfig, setSortConfig] = useState<{
    key: keyof Payment;
    direction: 'asc' | 'desc';
  } | null>(null);

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelectedPayments(payments.map(payment => payment.id));
    } else {
      setSelectedPayments([]);
    }
  };

  const handleSelectPayment = (paymentId: string) => {
    setSelectedPayments(prev => {
      if (prev.includes(paymentId)) {
        return prev.filter(id => id !== paymentId);
      } else {
        return [...prev, paymentId];
      }
    });
  };

  const handleSort = (key: keyof Payment) => {
    setSortConfig({
      key,
      direction: sortConfig?.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc',
    });
  };

  const getBankNameStyle = (bankName: string) => {
    switch (bankName) {
      case 'HDFC':
        return 'bg-secondary/10 text-secondary dark:bg-secondary/20';
      case 'SBI':
        return 'bg-primary/10 text-primary dark:bg-primary/20';
      default:
        return 'bg-gray-100 text-gray-800 dark:bg-dark-200 dark:text-gray-300';
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold text-gray-900 dark:text-white">Payments Details</h1>
      </div>

      {/* Financial Metrics */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {financialMetrics.map((metric) => {
          const Icon = metric.icon;
          return (
            <div key={metric.label} className="bg-white dark:bg-dark-100 rounded-lg shadow-sm overflow-hidden">
              <div className="px-4 py-3 bg-primary/10 dark:bg-primary/20 border-b border-primary/20">
                <div className="flex items-center">
                  <Icon className={`h-5 w-5 ${metric.color}`} />
                  <h3 className="ml-2 text-sm font-medium text-gray-900 dark:text-white">{metric.label}</h3>
                </div>
              </div>
              <div className="p-4">
                <div className="flex items-baseline justify-between">
                  <CurrencyDisplay 
                    amount={metric.value} 
                    className="text-2xl font-semibold text-gray-900 dark:text-white" 
                  />
                  <span className={`text-sm font-medium ${metric.color}`}>
                    {metric.trend}
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      {/* Payments Table */}
      <div className="bg-white dark:bg-dark-100 rounded-lg shadow-sm overflow-hidden">
        <div className="px-4 py-3 bg-primary/10 dark:bg-primary/20 border-b border-primary/20">
          <h2 className="text-lg font-medium text-primary">Payment History</h2>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200 dark:divide-dark-300">
            <thead className="bg-gray-50 dark:bg-dark-200">
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400">
                  <input
                    type="checkbox"
                    className="rounded border-gray-300 text-primary focus:ring-primary dark:border-dark-300 dark:bg-dark-100"
                    checked={selectedPayments.length === payments.length}
                    onChange={handleSelectAll}
                  />
                </th>
                <th 
                  scope="col" 
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 cursor-pointer"
                  onClick={() => handleSort('invoiceNumber')}
                >
                  <div className="flex items-center space-x-1">
                    <span>Invoice Number</span>
                    <div className="flex flex-col">
                      <ChevronUp className="h-3 w-3" />
                      <ChevronDown className="h-3 w-3" />
                    </div>
                  </div>
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400">
                  Invoice Date
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400">
                  Amount
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400">
                  Bank Name
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white dark:bg-dark-100 divide-y divide-gray-200 dark:divide-dark-300">
              {payments.map((payment) => (
                <tr 
                  key={payment.id} 
                  className="hover:bg-gray-50 dark:hover:bg-dark-200"
                >
                  <td className="px-6 py-4 whitespace-nowrap">
                    <input
                      type="checkbox"
                      className="rounded border-gray-300 text-primary focus:ring-primary dark:border-dark-300 dark:bg-dark-100"
                      checked={selectedPayments.includes(payment.id)}
                      onChange={() => handleSelectPayment(payment.id)}
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-white">
                    {payment.invoiceNumber}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                    {payment.invoiceDate}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <CurrencyDisplay 
                      amount={payment.amount} 
                      className="text-sm font-medium text-gray-900 dark:text-white" 
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${getBankNameStyle(payment.bankName)}`}>
                      {payment.bankName}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <button className="text-gray-400 hover:text-gray-500 dark:text-gray-500 dark:hover:text-gray-400">
                      <MoreVertical className="h-5 w-5" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}