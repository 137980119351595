import React, { useState } from 'react';
import { FileText, Download, MoreVertical, ChevronDown, ChevronUp } from 'lucide-react';

interface Report {
  id: string;
  documentType: string;
  description: string;
  documentUrl: string;
}

const reports: Report[] = [
  {
    id: '1',
    documentType: 'Inspection Report',
    description: 'Details of the physical inspection of assets',
    documentUrl: '#',
  },
  {
    id: '2',
    documentType: 'Recycling Compliance',
    description: 'Documentation of eco-friendly recycling processes',
    documentUrl: '#',
  },
  {
    id: '3',
    documentType: 'Data Wiping Certificate',
    description: 'Certification of secure data erasure',
    documentUrl: '#',
  },
  {
    id: '4',
    documentType: 'Asset Disposal Report',
    description: 'Details of disposed assets and methods used',
    documentUrl: '#',
  },
];

export default function DocumentsPage() {
  const [selectedReports, setSelectedReports] = useState<string[]>([]);
  const [sortConfig, setSortConfig] = useState<{
    key: keyof Report;
    direction: 'asc' | 'desc';
  } | null>(null);

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelectedReports(reports.map(report => report.id));
    } else {
      setSelectedReports([]);
    }
  };

  const handleSelectReport = (reportId: string) => {
    setSelectedReports(prev => {
      if (prev.includes(reportId)) {
        return prev.filter(id => id !== reportId);
      } else {
        return [...prev, reportId];
      }
    });
  };

  const handleSort = (key: keyof Report) => {
    setSortConfig({
      key,
      direction: sortConfig?.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc',
    });
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold text-gray-900 dark:text-white">Reports</h1>
      </div>

      <div className="bg-white dark:bg-dark-100 rounded-lg shadow-sm overflow-hidden">
        <div className="px-4 py-3 bg-primary/10 dark:bg-primary/20 border-b border-primary/20">
          <h2 className="text-lg font-medium text-primary">Documents List</h2>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200 dark:divide-dark-300">
            <thead className="bg-gray-50 dark:bg-dark-200">
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400">
                  <input
                    type="checkbox"
                    className="rounded border-gray-300 text-primary focus:ring-primary dark:border-dark-300 dark:bg-dark-100"
                    checked={selectedReports.length === reports.length}
                    onChange={handleSelectAll}
                  />
                </th>
                <th 
                  scope="col" 
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 cursor-pointer"
                  onClick={() => handleSort('documentType')}
                >
                  <div className="flex items-center space-x-1">
                    <span>Document Type</span>
                    <div className="flex flex-col">
                      <ChevronUp className="h-3 w-3" />
                      <ChevronDown className="h-3 w-3" />
                    </div>
                  </div>
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400">
                  Description
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white dark:bg-dark-100 divide-y divide-gray-200 dark:divide-dark-300">
              {reports.map((report) => (
                <tr 
                  key={report.id} 
                  className="hover:bg-gray-50 dark:hover:bg-dark-200"
                >
                  <td className="px-6 py-4 whitespace-nowrap">
                    <input
                      type="checkbox"
                      className="rounded border-gray-300 text-primary focus:ring-primary dark:border-dark-300 dark:bg-dark-100"
                      checked={selectedReports.includes(report.id)}
                      onChange={() => handleSelectReport(report.id)}
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <FileText className="h-5 w-5 text-primary mr-2" />
                      <span className="text-sm text-gray-900 dark:text-white">{report.documentType}</span>
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <span className="text-sm text-gray-500 dark:text-gray-400">{report.description}</span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center space-x-4">
                      <button
                        onClick={() => {/* Handle download */}}
                        className="text-primary hover:text-primary/80"
                      >
                        <Download className="h-5 w-5" />
                      </button>
                      <button className="text-gray-400 hover:text-gray-500 dark:text-gray-500 dark:hover:text-gray-400">
                        <MoreVertical className="h-5 w-5" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}