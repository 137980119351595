import { create } from 'zustand';
import type { AuthState } from '../types/auth';

const ADMIN_EMAIL = 'info@dwmpl.com';
const ADMIN_PASSWORD = 'Dwmpl@123';

export const useAuthStore = create<AuthState>((set) => ({
  user: null,
  isAuthenticated: false,
  isLoading: false,
  error: null,

  login: async (email: string, password: string) => {
    set({ isLoading: true, error: null });
    try {
      if (email === ADMIN_EMAIL && password === ADMIN_PASSWORD) {
        set({
          user: {
            id: '1',
            firstName: 'Admin',
            lastName: 'User',
            email: ADMIN_EMAIL,
            organization: 'Deshwal Waste Management',
            phone: '1800-102-9077',
            role: 'admin',
            status: 'approved',
            createdAt: new Date().toISOString(),
          },
          isAuthenticated: true,
          isLoading: false,
          error: null,
        });
      } else {
        throw new Error('Invalid credentials');
      }
    } catch (error) {
      set({
        error: 'Invalid email or password',
        isLoading: false,
        user: null,
        isAuthenticated: false,
      });
      throw error;
    }
  },

  logout: () => {
    set({
      user: null,
      isAuthenticated: false,
      error: null,
    });
  },

  clearError: () => set({ error: null }),
}));