import React, { useState } from 'react';
import { Link, useLocation, Routes, Route, Navigate } from 'react-router-dom';
import { 
  LayoutDashboard, 
  Package, 
  FileText, 
  IndianRupee, 
  LifeBuoy,
  Truck,
  BarChart2,
  Menu,
  X
} from 'lucide-react';
import Header from './Header';
import DashboardPage from '../../pages/DashboardPage';
import OrdersPage from '../../pages/OrdersPage';
import DocumentsPage from '../../pages/DocumentsPage';
import FinancePage from '../../pages/FinancePage';
import SupportPage from '../../pages/SupportPage';
import ProfilePage from '../../pages/ProfilePage';
import LogisticsPage from '../../pages/LogisticsPage';
import AnalyticsPage from '../../pages/AnalyticsPage';
import CertificatesPage from '../../pages/CertificatesPage';

const navigation = [
  { name: 'Dashboard', href: '/dashboard', icon: LayoutDashboard },
  { name: 'Order Details Page', href: '/orders', icon: Package },
  { name: 'Reports', href: '/documents', icon: FileText },
  { name: 'Logistics and Tracking', href: '/logistics', icon: Truck },
  { name: 'Analytics and Graphs', href: '/analytics', icon: BarChart2 },
  { name: 'Customer Support', href: '/support', icon: LifeBuoy },
  { name: 'Payment and Billing', href: '/finance', icon: IndianRupee },
  { name: 'Certificates', href: '/certificates', icon: FileText },
];

export default function DashboardLayout() {
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-dark">
      {/* Mobile menu button */}
      <button
        onClick={toggleSidebar}
        className="fixed z-50 bottom-4 right-4 lg:hidden bg-primary text-white p-3 rounded-full shadow-lg"
      >
        {isSidebarOpen ? (
          <X className="h-6 w-6" />
        ) : (
          <Menu className="h-6 w-6" />
        )}
      </button>

      {/* Overlay */}
      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40 lg:hidden"
          onClick={toggleSidebar}
        />
      )}

      {/* Sidebar */}
      <div
        className={`fixed inset-y-0 left-0 z-50 w-64 bg-white dark:bg-dark-100 transform transition-transform duration-300 ease-in-out lg:translate-x-0 ${
          isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
        }`}
      >
        <div className="flex flex-col h-full">
          <div className="flex items-center h-16 px-4 border-b border-gray-200 dark:border-dark-300">
            <div className="flex flex-col">
              <h1 className="text-lg font-bold text-primary">DESHWAL</h1>
              <p className="text-xs text-neutral">E-WASTE MANAGEMENT</p>
            </div>
          </div>
          
          <nav className="flex-1 px-4 py-4 space-y-1 overflow-y-auto">
            {navigation.map((item) => {
              const Icon = item.icon;
              const isActive = location.pathname === item.href;
              
              return (
                <Link
                  key={item.name}
                  to={item.href}
                  onClick={() => setIsSidebarOpen(false)}
                  className={`flex items-center px-4 py-2 text-sm font-medium rounded-lg transition-colors ${
                    isActive
                      ? 'bg-primary/10 text-primary dark:bg-primary/20'
                      : 'text-neutral hover:bg-primary/5 dark:text-gray-300 dark:hover:bg-dark-200'
                  }`}
                >
                  <Icon className="w-5 h-5 mr-3" />
                  {item.name}
                </Link>
              );
            })}
          </nav>
        </div>
      </div>

      {/* Main content */}
      <div className={`lg:pl-64 flex flex-col min-h-screen`}>
        <Header />
        <main className="flex-1 p-4 lg:p-6 bg-gray-50 dark:bg-dark">
          <Routes>
            <Route index element={<Navigate to="/dashboard" replace />} />
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route path="/orders" element={<OrdersPage />} />
            <Route path="/documents" element={<DocumentsPage />} />
            <Route path="/logistics" element={<LogisticsPage />} />
            <Route path="/analytics" element={<AnalyticsPage />} />
            <Route path="/finance" element={<FinancePage />} />
            <Route path="/support" element={<SupportPage />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/certificates" element={<CertificatesPage />} />
          </Routes>
        </main>
      </div>
    </div>
  );
}